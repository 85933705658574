import React, {useState, useEffect, useContext} from "react";
import axios from "axios";
import AuthContext from "../../context/AuthProvider";
import { useNavigate } from "react-router-dom";
import { Card, Container, Form, Row, Col, Button  } from "react-bootstrap";




export default function ArticlePage() {

    const [isMounted, didMount] = useState(false)
    const [loading, isLoading] = useState(true)
    const {auth, setAuth} = useContext(AuthContext)
    const naviage = useNavigate()


    const [errMsg, setErrMsg] = useState('')
    const [article, setArticles] = useState([])

    const articleCategories = [ 
        'News', 
        'Blog Post', 
        'Reseach Discussion', 
        'Website Team Update', 
        'Board of Directors', 
        'CStaRS',
        "Other"]


    const [articleData, setArticleData] = useState({
        title: "",
        text: "",
        unique_id: null,
        author: auth.displayName,
        author_email: auth.email,
        date_submitted: "",
        category: "",
        image_url: "",
        slug: null

    })

    const [images, setImages] = useState([])

    useEffect(()=> {
        
        async function getArticles() {

            const response = await axios.get("/article/all").then(result=> {
                return {
                    success: true,
                    message: "successful retrieved articles",
                    data: result.data
                }
               
            }).catch(err=> {
                return {
                    success: false,
                    message: "Sorry, could not retreive article archive data from db",
                    errMsg: err,
                    data: null
                }
            })
        }

        //get all the articles from db
        getArticles().then(result=> {
            if (result.success == false) {
                setErrMsg(result.message)
                console.log(errMsg)
            } else if (result.success == true) {
                console.log(result.message)
                setArticles(result.data)
            }
        })
        //api call to get an array of already exisiting articles


        didMount(true)


        
    }, [])

    useEffect(()=> {
        if (isMounted) {
            isLoading(false)
        }
    }, [isMounted])



    // Handle user input
    const handleChange = (e) => {
            e.preventDefault()
            const { name, value } = e.target;
            setArticleData ({
              ...articleData,
              [name]: value,
            });
          };


    //handle image upload

    const handleFileUpload = (e) => {
        const { name, value } = e.target
        
        //get the file bits
        const imageFile = e.target.files[0];

        //rename the poster
        const newFileName = Date.now()

        //create new file object w/ renamed poster
        const newImageFile = new File ([imageFile], newFileName, {
            type: imageFile.type
        })

        //store new name w/ form data
        setArticleData({
            ...articleData,
            image_url: newFileName
          });


        //store image data is use state
        setImages(newImageFile)
    }


    const handleSubmit = async (e) => {
        e.preventDefault()

        let formData = new FormData()


        formData.append("articleData", JSON.stringify(articleData))
        formData.append("images", images)

        console.log(articleData, images)

        axios.post("/article/add", formData, { 
            headers: {'Content-Type': 'multipart/form-data'}
        }).then(result=> {
            if (result.success) {
                console.log("Article successfully saved in db")
                setArticleData({
                    title: "",
                    text: "",
                    unique_id: null,
                    prefix: auth.prefix,
                    author: auth.displayName,
                    author_email: auth.email,
                    date_submitted: "",
                    category: "",
                    image_url: "",
                    slug: null
                })

                setImages([])
            }
        }).catch(err=> {
            console.log(err)
            setErrMsg("Not able to save article at the moment. Please try again later")
        })
        

    }

    if (!loading) return (
        <Container>

            {/* form to submit articles */}
            <Row className="mt-5">
                <Col></Col>
                <Col xs={10} sm={8}>
                    <Card>
                        <Card.Body>
                            <h5>  Article Submission Form </h5>
                            <Form  onSubmit={handleSubmit} className="mt-1" encType="multipart/form-data">
                                <Form.Group className="mt-2">
                                    <Form.Control 
                                        type="text" 
                                        name="title" 
                                        placeholder="Article title"
                                        value={articleData.title}
                                        onChange={handleChange}
                                        required />
                                </Form.Group>

                                <Form.Group className="mt-2">
                                    <Form.Control 
                                        as="textarea" 
                                        name="text"
                                        rows={5} 
                                        placeholder=""
                                        value={articleData.text}
                                        onChange={handleChange}
                                        required />
                                </Form.Group >


                                <Form.Group className="mt-2">

                                    <Form.Select 
                                        id="category"
                                        name="category"
                                        value={articleData.category}
                                        onChange={handleChange}
                                        required
                                    >
                                    
                                        <option value=""> Select Category</option>
                                        {
                                            articleCategories.map(item=> {
                                                return(
                                                    <option value={item}> {item} </option>
                                                )
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group controlId="eventPoster" className='mt-2'>
                                <Form.Label> Include image(s) (must be image format, e.g. jpeg/jpg, png, etc.)</Form.Label>
                                <Form.Control
                                    type="file"
                                    name="poster"
                                    placeholder=''
                                    accept="image/*"
                                    onChange={e=> handleFileUpload(e)}
                                    required
                                />
                            </Form.Group>

                                <Button variant="warning" type="submit" style={{ width: "15rem", }} className='mt-2'>
                                    Publish
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col></Col>
            </Row>


            {/* cards which display list of archived articles */}
            
            <Row className="mt-5">
                <Col></Col>
                <Col xs={12} sm={10}></Col>
                <Col></Col>
            </Row>
            
        </Container>

    )
}