import './App.css';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import Home from './components/home/home.component';
import NavMenu from './components/navmenu/nav.component';
import Register from './components/users/register/register.component';
import SignupMembership from './components/users/register/signup-membership.component';
import OrderRecieptSuccess from './components/users/register/order-success.component';
import OrderRecieptCancel from './components/users/register/order-cancel.component';
import MembershipInfo from './components/membershipInfo.component';
import EventsPage from './components/events/events.component';
import ArticlePage from './components/articles/article.component';
import SingleEvent from './components/events/singleevent.component';
import AboutPage from './components/about/aboutpage.component';
import ContactUs from './components/contact/contactus.component';
import Footer from './components/footer/footer.component';
// import Dashboard from './components/users/portal/member/dashboard.component';
import UserAccount from './components/users/account/useraccount.component';
import AdminView from './components/users/portal/admin/adminview.component';
import HomeCSTARS from './components/cstars/cstars-home.component';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './components/users/login/login.component';
import VerifyAccount from './components/users/login/verifyaccount.component';
import { useState, useEffect, useContext } from 'react';

import AuthContext from './context/AuthProvider';
import ChangePassword from './components/users/account/changepwd.component';
import OpportunitiesPage from './components/opportunities/opportunities_page';
import FAQPage from './components/about/FAQ.component';
import ErrorPage from './components/errors/errorpage.component';


export default function ClientRoutes() {
  const {auth, setAuth} = useContext(AuthContext)
  const [isMounted, didMount] = useState(false)
  const [loading, isLoading] = useState(true)

  useEffect(()=> {
    didMount(true)
  }, [])

  useEffect(()=> {
    if (isMounted & auth != null) {
      // console.log(auth.loggedIn)
      isLoading(false)
    }}, [auth, isMounted])
  

  if (!loading) return (
    <BrowserRouter>
      <Row id="nav">
        < NavMenu />
      </Row>
      <Row id="main">

          <Routes>
            <Route path="/news" element={ <Home />} />

            <Route path="/events/*" element={ <EventsPage />} />

            <Route path="/articles/:id?" element={ <ArticlePage />} />

            <Route path="/event-view/:id?" element = {<SingleEvent /> } />
            
            <Route path="/about" element={ <AboutPage/>} />
            <Route path="/FAQ" element={ <FAQPage />} />
            <Route path="/contactus" element={ <ContactUs />} />

            <Route path="/cstars" element={ <HomeCSTARS />} />

            <Route path="/register" element={ !auth.loggedIn ? (<Register />) : (< Navigate to="/" />)} />

            <Route path="/signup-membership" element={ !auth.loggedIn ? (<Register />) : (<SignupMembership />)} />

            <Route path="/order/cancel" element={ auth.loggedIn ? (<OrderRecieptCancel />): (< Navigate to="/login" />) } />
            <Route path="/order/success" element={ auth.loggedIn ? (<OrderRecieptSuccess />): (< Navigate to="/login" />) } />

            <Route path="/login" element={ !auth.loggedIn ? (<Login />): (<UserAccount />)} />

            <Route path="/verify-account" element={ <VerifyAccount /> } />

            <Route path="/change-password" element={  <ChangePassword /> } />

            <Route path="/myaccount/:user?" element={ auth.loggedIn?  (<UserAccount />):(< Navigate to="/login" /> ) } />


            {/* <Route path="/dashboard/:id?" element={auth.loggedIn?  (<Dashboard />): (< Navigate to="/login" /> )} /> */}

            <Route path="/admin/:user?" element={ auth.loggedIn?  (<AdminView />):(< Navigate to="/" /> )} />

            <Route path="/membership" element={ <MembershipInfo />} />

            <Route path="/opportunities/:focus?" element= {<OpportunitiesPage />} />
            <Route path="/errorpage" element={ <ErrorPage />} />

            <Route path="/" element={ <Home />} />

            {/* <Route path="/*" element={ <Home />} /> */}

            {/* <Route path="/*" element={ < Navigate to="/" />} /> */}
          </Routes>
          
      </Row>

      <Row id="footer" className=''>
        <Footer />
      </Row>

    </BrowserRouter>
  );
}

