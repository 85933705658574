import React, { useContext, useState, useEffect } from 'react';
import { Card, Col, Container, Row, Image, ListGroup } from 'react-bootstrap';
import MembershipTable from './membershiptable.component';
import AuthContext from '../../../../context/AuthProvider';



export default function AdminView() {
    const {auth, setAuth} = useContext(AuthContext)
    const [isMounted, didMount] = useState(false)
    const [loading, isLoading] = useState(true)

    useEffect(()=> {
        didMount(true)
    }, [])
    useEffect(()=> {
        if (isMounted) isLoading(false)
    }, [auth, isMounted])

    if (!loading) return (
        <Container className='mt-50' style={{ height: "100%"}}>
            
            <Row className='h100 mt-5 mb-90'>
                <Col sm={1} className='mb-3'></Col>
                <Col xs={12} sm={10} className="mb-3 h100" >
                    < MembershipTable />
                </Col>
                <Col sm={1} className='mb-3'></Col>
            </Row>
        </Container>
        )
}