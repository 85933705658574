import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridValueGetterParam, getGridStringOperators } from '@mui/x-data-grid';
import AuthContext, { guestUser } from '../../../../context/AuthProvider';
import { Envelope, PencilSquare, PersonSquare, Pencil, XSquare, Save, XCircle, Check2Circle, Check, CheckCircle, Person, Download, Gear, GearWideConnected, GearFill } from 'react-bootstrap-icons';
import axios from 'axios';
import { Card, Col, Form, InputGroup, Row, Alert, Modal, Button, Nav, Navbar, Container, Dropdown, DropdownButton, FormSelect, FormGroup } from 'react-bootstrap';



const stringOperators = getGridStringOperators();

const columns = [

  {
    field: 'displayname',
    headerName: 'Full name',
    sortable: true,
    width: 170,
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.firstname || ''} ${params.row.lastname || ''}`,
  },
  {
    field: "role",
    headerName: "Role",
    sortable: true,
    width: 125,
  },
  {
    field: "email",
    headerName: "Email",
    sortable: true,
    width: 220,
  },
  {
    field: "membershiptype",
    headerName: "Membership Type",
    sortable: true,
    width: 200,
  },
  {
    field: "membershipstatus",
    headerName: "Membership Status",
    sortable: true,
    width: 160,
  }, {
    field: "accountverified",
    headerName: "Account Verified",
    sortable: true,
    width: 160,
  }
];

const rows = [];


export default function MembershipTable() {
  const {auth, setAuth} = useContext(AuthContext)
  const [isMounted, didMount] = useState(false)
  const [loading, isLoading] = useState(true)
  const [users, setUsers] = useState(rows)
  const [filteredMembers, setFilteredMembers] = useState(0)
  const [verifiedUsers, setVerifiedUsers] = useState(0)
  const [userSelected, selectUser] = useState(null)

  const [errMsg, setErrMsg] = useState("")
  const [showSuccessChangeMsg, setShowSuccessChangeMsg] = useState(false)
  const [isEditingRole, editRole ] = useState(false)
  const [isEditingMembership, editMembership] = useState(false)

  const [newRole, setNewRole] = useState('')

  const DownloadEmailListOptions = ["Select", "Members", "Verified users", "All"]

  const [showDownloadOptions, setShowDownloadOptions] = useState(false)
  const [downloadURL, setURL] = useState('')
  const handleDownloadModal = () => {
    setShowDownloadOptions(!showDownloadOptions)
    console.log("Modal closed", showDownloadOptions)
    // if modal is closed
    if (showDownloadOptions) {
      setEmailListCategory("Select")
    }
  }

  const [selectedEmailList, setEmailListCategory] = useState("Select")
  const [showDldBox, setShowDldBox] = useState(false)
  //state values to help filter table
  const [filt, setFilt] = useState([])
  const getUsers = async () => {
    const res = await axios.get("/user/all")
   // console.log(res)
    return res.data.response
  }

  useEffect(()=> {
    getUsers()
    .then((result)=> {

      // console.log(result)
      setUsers(result)
      // Filter array to get objects with 'isMember' set to true
      let filteredMembers = result.filter(member => member.ismember === 'true');
      // filter to get all the verified users
      let filteredVerifiedUsers = result.filter (user => user.accountverified == 'true')

      setFilteredMembers(filteredMembers)
      setVerifiedUsers(filteredVerifiedUsers)
      setErrMsg("")
    }).catch(err => {
      setErrMsg("Not able to find users.")
    })

    didMount(true)
  }, [])

  useEffect(()=> {
    console.log(filt)
  }, [filt])

  useEffect(()=> {
    if (isMounted) {
      isLoading(false)
    }
  }, [isMounted, auth])
  
  useEffect(()=> {
    console.log(userSelected)
    //if new user selected, waant to erase previous changes
    editRole(false)
    setNewRole('')

  },[userSelected])

  const handleRowClick = (params) => {
    setShowSuccessChangeMsg(false)

    let newRowSelected = {...userSelected}
    newRowSelected = params.row
    selectUser(newRowSelected)

  
  }

  useEffect(()=> {
    
  }, [selectedEmailList])

  const handleDownload = (e)=> {
    e.preventDefault()

    const email_lists = ["isMember", "Verified", "All"]
    
    // Select list to download: Members only, verified user's list, or all emails
    setShowDownloadOptions(true)

    // db query, SELECT fullname, prefix, email, verified, ismember, membershiptype, 
    // FROM csam_users WHERE above criteria is met
    
    // convert email to csv file

    //client-side download files
  }
  


  const createUserList = async (e) => {
    e.preventDefault()
    setShowDldBox(true)
    const filter = { selectedEmailList }
    const dbUrl = '/user/create-user-list'
    //axios call to db
    const response = await axios.post(dbUrl, filter)
      .then((result)=> {
        if (result.data.success) {
          let csvData = result.data.dta
          
          //create csv file

          const csvBlob = new Blob ([csvData], { type: 'text/csv' })
          const url = URL.createObjectURL(csvBlob)
          
          setURL(url)

        } else {
          console.log(result.data)
          throw 'error'
        }
      }).catch((error)=> {
        console.log(error)
      })
  }
  const showPreviewOfDownload = (e) => {
    e.preventDefault()
    console.log(selectedEmailList)
  }
  function displayPlainTxt (variableTitle, variableValue) {
    return (
        <Form.Group as={Row} className="mb-1" controlId="formPlaintextEmail">
        <Form.Label column sm="5">
          { variableTitle }
        </Form.Label>
        <Col sm="5">
          <Form.Control plaintext readOnly value= { variableValue } />
        </Col>
      </Form.Group>
    )
}

  const viewSelectedUser = () => {

    return (
        <>
          <Card>
              <Card.Body>
              <Form>
                <h5> Selected User Information </h5>

                { displayPlainTxt("Name", userSelected.displayname) }
                { displayPlainTxt("Email", userSelected.email) }
                <Form.Group as={Row} className="mb-1" controlId="formPlaintextEmail">
                    <Form.Label column sm="5">
                      Role < Pencil size={13} className='mx-1' onClick={ (e) => editRole(true)}/>
                    </Form.Label>
                    
                    <Col sm="5">
                      
                      { isEditingRole == false? (
                         <Form.Control plaintext value= { userSelected.role } />
                      ):(
                        <Form.Select
                          value = { newRole }
                          onChange = {e=> setNewRole(e.target.value)}
                          aria-label="Default select example">
                          <option value={ userSelected?.role }> Change {userSelected.firstName+"'s"} role </option>
                          <option value="subscriber">Subscriber/General</option>
                          <option value="administrator">Administrator</option>
                          <option value="editor">Editor</option>
                        </Form.Select>
                      )
                      }
                     
                     
                    </Col>
                </Form.Group>
                
                
                
                { displayPlainTxt("Membership Type", userSelected.membershiptype)}
                

                <div style={{ textAlign: "center"}}>
              
                      {
                        newRole != '' ? (
                        <>
                          <Button className='mx-1'  type='button' variant="outline-primary" onClick= { e => {updateUser(e)}}>
                            Save <Save />
                          </Button>
                          <Button className='mx-1' type='button' variant="outline-danger"onClick= { e => {selectUser(null); newRole(''); editRole(false); setShowSuccessChangeMsg(false)}}>
                            Cancel <XCircle />
                          </Button>
                        </>
                        ): (<></>)
                      } 
                </div>
              </Form> 
              </Card.Body>
          </Card>
        </>

      
    )
  }

  const colTargetValue = (id) => {
    let value = null;
    if ( id == 'membershipstatus') {
      value = 'active'
    } else if ( id == 'role') {
      value = 'administrator'
    } else if (id == 'accountverified') {
      value = 'true'
    } else {
      value = []
    }

    return value
  }
  const updateUser = async (e) => {
    e.preventDefault()
    const filter = { email: userSelected.email}

    const update = {role: newRole}
    
    //console.log(filter, update)
   
    const userUpdated = await axios.post('/user/update', { filter, update })
      .then( (res) => {
        return res
      }).catch( (err) => {
        console.log(err)
        setErrMsg("SERVER-SIDE ERROR! Changes to user could not be made")
      })
      
      console.log(userUpdated)

    if (userUpdated.data.success) {


      await getUsers().then((result)=> {
        // console.log(result)
        setUsers(result)

        const x = {...userSelected}
        x.role = newRole
        selectUser(x) 
        setErrMsg("")
      }).catch(err => {
        setErrMsg("Not able to find users.")
      })

      setNewRole('')
      editRole(false)
      setShowSuccessChangeMsg(true)
    }
    }
  
  if (!loading) return (
    <div>
      {
        errMsg != '' ? (
          <Row>
            <Col>
            <Alert variant="danger" style={{ textAlign: "center"}}>
              { errMsg }
            </Alert>
            </Col>
            <Col xs={1}>
            </Col>
          </Row>
      ): (<></>)
      }

{ showSuccessChangeMsg ? (
          <Row>
            <Col></Col>
            <Col sm={10}>
              <Alert variant='light' style={{ textAlign: "center"}}>
                <CheckCircle size={25} style={{ color: "green"}}/> Changes Saved (Refresh page to see changes reflected on table.) 
              </Alert>
            </Col>
            <Col></Col>
          </Row>):(<></>)
          }


      <Row className='justify-content-center mb-3'>
        <Col sm={5}>
            <Card style={{ height: '100%'}}>
              <Card.Body>
                <h5> Analytics </h5>
                { displayPlainTxt("Total Users", users?.length) } 

                { displayPlainTxt("Verified Users", verifiedUsers?.length) }

                { displayPlainTxt("Members", filteredMembers?.length) }

              </Card.Body>
            </Card>
        </Col>

        <Col xs={12} sm={6}>
          {   
            userSelected == null ? (<></>) : ( viewSelectedUser () ) 
          }
        </Col>
      </Row>
      
      <Row className='mt-3 justify-content-center' >
        <Navbar style={{ width: "90%"}} bg="light" variant="light">
              <div style={{ width: "200px", padding: "3px"}}>
                <Form.Select
                  onChange={(e)=> {
                    setFilt([
                      {
                        columnField: e.target.value,
                        operatorValue: "equals",
                        value: colTargetValue(e.target.value)
                      }
                    ])
                  }}>
                    <option value="reset">Filter users</option>
                    <option value="accountverified">Verified users</option>
                    <option value="membershipstatus">Members</option>
                    <option value="role">Administrator</option>
                </Form.Select>
              </div>

              <Button onClick={e=> handleDownload(e)}> <Download /> </Button>
              

                <Modal show={showDownloadOptions} onHide={handleDownloadModal}>
                    <Modal.Header closeButton>
                      <Modal.Title>Download Options</Modal.Title>
                    </Modal.Header>
                      <Modal.Body>
                          
                        <p>Select user list you would like to create </p>
                        <Form>
                            <Form.Select onChange={e=> setEmailListCategory(e.target.value)}aria-label="Download list options">
                            { DownloadEmailListOptions.map(item=>{
                                return( <option value={item}>{item}</option> )
                              })}
                            </Form.Select>

                          <Button className= {selectedEmailList != 'Select' ? 'show mt-3':'hide' } onClick={e=> createUserList(e)}>  Create list </Button>

                          <Card className={ showDldBox && selectedEmailList != 'Select'? "show mt-3": "hide"}>
                              <Card.Body>
                                Click the link below to download .csv file containing an updated list of '{ selectedEmailList }'. 
                                <br/>
                                <a href={downloadURL} download={'emailList-'+ selectedEmailList }> Download CSV File </a>
                              </Card.Body>
                          </Card>

                        </Form>
                      </Modal.Body>

                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleDownloadModal}>
                        Close
                      </Button>
                      <Button variant="primary" onClick={handleDownloadModal}>
                        Save Changes
                      </Button>
                    </Modal.Footer>
                </Modal>

        </Navbar>
      </Row>

      <Row className='mt-3 justify-content-center'> 
        <Box style={{ padding: "0px"}}sx={{ height: 700, width: '90%' }}>
            <DataGrid
              rows={users}
              columns={columns}
              getRowId = {(row) => Math.random()}
              pageSize={11}
              rowsPerPageOptions={[5]}
              checkboxSelection
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              onRowClick={ handleRowClick }
              filterModel={{
                items: filt
              }}
              
          />
        </Box>
      </Row>

    </div>

  );
}