
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Instagram, Twitter, Linkedin, Slack } from 'react-bootstrap-icons';
import { redirect } from 'react-router-dom';

export default function Footer() {

    const redirectOnClick = (mediaTarget) => {
        if (mediaTarget == "instagram") {
            window.open("https://www.instagram.com/csam_scma/?hl=en")
        } else if (mediaTarget == "twitter") {
            window.open("")
        } else if (mediaTarget == "linkedin") {
            window.open("https://www.linkedin.com/company/canadian-society-for-aerospace-medicine-student-resident-section-cstars/")
        }
    }
    
    const socialMediaIcons = () => {

        return(
            <div>
                <Instagram style={{ cursor: "pointer" }}onClick={e=>redirectOnClick("instagram")} className='social-icons mx-1'/>  
                {/* <Twitter onClick={e=>redirectOnClick("twitter")} className='social-icons'/>   */}
                <Linkedin style={{ cursor: "pointer" }} onClick={e=>redirectOnClick("linkedin")} className='social-icons mx-1'/>
                {/* <Slack  onClick={e=>redirectOnClick("slack")} className='social-icons'/> */}
            </div>
        )
    }
    return (
        <Container fluid className=''>
            <Row className="navbar-custom">
                <Row>
                    <Col>
                        <ul className='ul-footer'>
                            <li> <a href="/"> Home </a></li>
                            <li> <a href="/"> News</a></li>
                            <li> <a href="/events"> Events </a></li>
                            <li> <a href="/membership"> Members </a></li>
                        </ul>
                    </Col>
                    <Col >
                        <ul className='ul-footer'>
                            <li> <a href="/about"> About </a></li>
                            <li> <a href="/contactus"> Contact Us </a></li>
                            <li> <a href="/FAQ"> FAQ </a></li>
                           
                        </ul>
                    </Col>
                    <Col>
                        <ul className='ul-footer'>
                            <li>  { socialMediaIcons() } </li>
                            <li> { }</li>
                        </ul>
                        
                    </Col>
                </Row>
                
                <Row>
                    <Col></Col>
                    <Col xs={10} style={{ color: "white", textAlign: "center", fontSize: "0.75rem"}}>
                        © 2023 CANADIAN SOCIETY OF AEROSPACE MEDICINE  
                    </Col>
                    <Col> </Col>
                </Row>

            </Row>

        </Container>
    )
}