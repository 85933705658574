import axios from 'axios'
import React, { useState, useEffect, useContext} from 'react'

import { Form, Button, Container, Col, Row, Card, ProgressBar, Alert, InputGroup, Modal } from 'react-bootstrap'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

// @type {PaymentsClient}
// @private
import AuthContext from '../../../context/AuthProvider';
const REGISTER_URL = 'http://localhost:8000/user/register'


export default function OrderRecieptCancel() {
    const {auth, setAuth} = useContext(AuthContext)
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const [isMounted, didMount] = useState(false)
    const [loading, isLoading] = useState(true)

    const [errMsg, setErrMsg] = useState('')
    const [isERROR, setError] = useState(false)


    useEffect(()=> {
        

        const sess_id = searchParams.get('session_id')



        if (auth.membershipStatus != "Active") {

            axios.post('/user/order/success', { 
                session_id: sess_id,
                user: auth,
                accessToken: localStorage.getItem('accessToken')
            })
            .then(response => {
                const data = response.data

                console.log(data)
                if (data.membership_updated) {
                    if (data?.newAccessToken) {
                        localStorage.setItem("accessToken", data.newAccessToken)
                    } else {
                        console.log("no new token")
                    }
                }

                didMount(true)

            }).catch(err => {
                // handles 
                setErrMsg(err.response.data.message)
                
            })

        } else {
            navigate("/myaccount", { replace: true })
        }


    },[])

    useEffect(()=> {
        isLoading(false)
    },[isMounted])
    
    if (!loading){
    return (
        <>
        <Container className='mt-150'>
                <Row>
                    <Col></Col>
                    <Col xs={8}>

                        <Alert variant='danger'>
                            Your payment did not go through. <a href="/signup-membership"> Please try again</a>.
                        </Alert>        
                        
                    </Col>
                    <Col></Col>
                </Row>
            </Container>
        </>
 

    )}


}