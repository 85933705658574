import axios from "axios";
import React from "react";
import { Alert, Button, Card, Col, Container, Row } from "react-bootstrap";

export default function ErrorPage () {
    
    return (
        <Container>
            <Row className="mt-150">
                <Col></Col>
                <Col xs={10} sm = {8} md={6}>
                    <Alert variant="danger">
                        
                        <h3> ...Houston, we have a problem </h3>
                        <p>
                            We've encountered an unexpected internal error and our team is already 
                            working to resolve it. In the meantime, please try refreshing 
                            the page or returning to our homepage. Thank you for your patience 
                            and understanding!
                        </p>
                    </Alert>
                </Col>
                <Col></Col>
            </Row>
        </Container>
    )
}