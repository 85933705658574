import axios from "axios"
import { FloatingLabel, Form } from "react-bootstrap"

export function test() {
    console.log("testing tools/main js")
}


function isISO8601(dateString) {
  const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{1,3})?(?:Z|[+-]\d{2}:\d{2})$/;
  return iso8601Regex.test(dateString);
}

//returns string Date in the format of year-monthInt-day e.g., 2023-05-10
export function strToDateObject (stringDate) {
        // console.log(stringDate)
        try  {

          // check if ISOString format, if not then convert to ISOString
          if (!isISO8601(stringDate)) {
            const new_date_object = new Date(stringDate)
            stringDate = new_date_object.toISOString()
          } 

          const date_string = (stringDate.split("T")[0]).split("-")

          const year = date_string[0]
          const month = parseInt(date_string[1]) - 1
          const day = date_string[2]
          
          const date_object = new Date(year, month, day)
          return date_object
        } catch(err) {
          console.log("Date preformated", err)
          return new Date(stringDate)
        }

    }

//reform inputed data, by reintroducing paragraphs

export function splitParagraphs (userText) {
  let split_para = userText.split('\n')
  return split_para
}

//toggle between pwd and text, 'element_ids' and array of elements
export function  showPwd (element_ids){
    // e.preventDefault()

    element_ids.map(el_id=>{
        var el = document.getElementById(el_id);
        if (el.type === "password") {
            el.type = "text";
        } else {
            el.type = "password";
        }

    })

}


// returns array of schools by prov
export function canadianUniversitiesByProvince () {

    const universities = [
        {
          province: 'Alberta',
          universities: [
            'Athabasca University',
            'Concordia University of Edmonton',
            'MacEwan University',
            'Mount Royal University',
            'The King’s University',
            'University of Alberta',
            'University of Calgary',
            'University of Lethbridge'
          ]
        },
        {
          province: 'British Columbia',
          universities: [
            'Emily Carr University of Art + Design',
            'Kwantlen Polytechnic University',
            'Royal Roads University',
            'Simon Fraser University',
            'The University of British Columbia',
            'Thompson Rivers University',
            'Trinity Western University',
            'University of Northern British Columbia',
            'University of the Fraser Valley',
            'University of Victoria',
            'Vancouver Island University'
          ]
        },
        {
          province: 'Manitoba',
          universities: [
            'Brandon University',
            'Canadian Mennonite University',
            'St. Paul’s College',
            'The University of Winnipeg',
            'Université de Saint-Boniface',
            'University of Manitoba'
          ]
        },
        {
          province: 'New Brunswick',
          universities: [
            'Mount Allison University',
            'St. Thomas University',
            'Université de Moncton',
            'University of New Brunswick'
          ]
        },
        {
          province: 'Nova Scotia',
          universities: [
            'Acadia University',
            'Cape Breton University',
            'Dalhousie University',
            'Mount Saint Vincent University',
            'Nova Scotia College of Art and Design (NSCAD University)',
            'Saint Mary’s University',
            'St. Francis Xavier University',
            'Université Sainte-Anne',
            'University of King’s College'
          ]
        },
        {
          province: 'Newfoundland & Labrador',
          universities: ['Memorial University of Newfoundland']
        },
        {
          province: 'Ontario',
          universities: [
            'Algoma University',
            'Brescia University College',
            'Brock University',
            'Carleton University',
            'Dominican University College',
            'Huron University College',
            'King’s University College (King’s University College at Western University)',
            'Lakehead University',
            'Laurentian University',
            'McMaster University',
            'Nipissing University',
            'OCAD University',
            'Queen’s University',
            'Redeemer University College',
            'Royal Military College of Canada',
            'Ryerson University',
            'Saint Paul University',
            'St. Jerome’s University',
            'Trent University',
            'University of Guelph',
            'University of Ontario Institute of Technology',
            'University of Ottawa',
            'University of St. Michael’s College',
            'University of Sudbury',
            'University of Toronto',
            'University of Trinity College',
            'University of Waterloo',
            'University of Windsor',
            'Victoria University',
            'Western University',
            'Wilfrid Laurier University',
            'York University'
          ]
        },
        {
          province: 'Prince Edward Island',
          universities: ['University of Prince Edward Island']
        },
        {
          province: 'Québec',
          universities: [
            'Bishop’s University',
            'Concordia University',
            'École de technologie supérieure',
            'École nationale d’administration publique',
            'HEC Montréal',
            'Institut national de la recherche scientifique',
            'McGill University',
            'Polytechnique Montréal',
            'TÉLUQ',
            'Université de Montréal',
            'Université de Sherbrooke',
            'Université du Québec',
            'Université du Québec à Chicoutimi',
            'Université du Québec à Montréal',
            'Université du Québec à Rimouski',
            'Université du Québec à Trois-Rivières',
            'Université du Québec en Abitibi-Témiscamingue',
            'Université du Québec en Outaouais',
            'Université Laval'
          ]
        },
        {
          province: 'Saskatchewan',
          universities: [
            'Campion College',
            'First Nations University of Canada',
            'Luther College',
            'St. Thomas More College',
            'University of Regina',
            'University of Saskatchewan'
          ]
        }, {
            province: 'Other',
            universities: ['Other']
        }
      ];

      return universities
      
}


//return array of canadian schools
export function canadianUniversities () {

    let universities = [
            'Athabasca University',
            'Concordia University of Edmonton',
            'MacEwan University',
            'Mount Royal University',
            'The King’s University',
            'University of Alberta',
            'University of Calgary',
            'University of Lethbridge',
            'Emily Carr University of Art + Design',
            'Kwantlen Polytechnic University',
            'Royal Roads University',
            'Simon Fraser University',
            'The University of British Columbia',
            'Thompson Rivers University',
            'Trinity Western University',
            'University of Northern British Columbia',
            'University of the Fraser Valley',
            'University of Victoria',
            'Vancouver Island University',
            'Brandon University',
            'Canadian Mennonite University',
            'St. Paul’s College',
            'The University of Winnipeg',
            'Université de Saint-Boniface',
            'University of Manitoba',
            'Mount Allison University',
            'St. Thomas University',
            'Université de Moncton',
            'University of New Brunswick',
            'Acadia University',
            'Cape Breton University',
            'Dalhousie University',
            'Mount Saint Vincent University',
            'Nova Scotia College of Art and Design (NSCAD University)',
            'Saint Mary’s University',
            'St. Francis Xavier University',
            'Université Sainte-Anne',
            'University of King’s College',
            'Memorial University of Newfoundland',
            'Algoma University',
            'Brescia University College',
            'Brock University',
            'Carleton University',
            'Dominican University College',
            'Huron University College',
            'King’s University College (King’s University College at Western University)',
            'Lakehead University',
            'Laurentian University',
            'McMaster University',
            'Nipissing University',
            'OCAD University',
            'Queen’s University',
            'Redeemer University College',
            'Royal Military College of Canada',
            'Ryerson University',
            'Saint Paul University',
            'St. Jerome’s University',
            'Trent University',
            'University of Guelph',
            'University of Ontario Institute of Technology',
            'University of Ottawa',
            'University of St. Michael’s College',
            'University of Sudbury',
            'University of Toronto',
            'University of Trinity College',
            'University of Waterloo',
            'University of Windsor',
            'Victoria University',
            'Western University',
            'Wilfrid Laurier University',
            'York University',
            'University of Prince Edward Island',
            'Bishop’s University',
            'Concordia University',
            'École de technologie supérieure',
            'École nationale d’administration publique',
            'HEC Montréal',
            'Institut national de la recherche scientifique',
            'McGill University',
            'Polytechnique Montréal',
            'TÉLUQ',
            'Université de Montréal',
            'Université de Sherbrooke',
            'Université du Québec',
            'Université du Québec à Chicoutimi',
            'Université du Québec à Montréal',
            'Université du Québec à Rimouski',
            'Université du Québec à Trois-Rivières',
            'Université du Québec en Abitibi-Témiscamingue',
            'Université du Québec en Outaouais',
            'Université Laval',
            'Campion College',
            'First Nations University of Canada',
            'Luther College',
            'St. Thomas More College',
            'University of Regina',
            'University of Saskatchewan',
            'Other'
      ];

      return universities.sort()
      
}


//array of prefixes
export const prefixes = ['Mr', 'Ms', 'Mrs', 'Dr']



// returns an array of url params
export function getURLParams() {
  const currentURL = window.location.href

  const urlArray = currentURL.split("/")

  //console.log(urlArray)
  return urlArray
}


// SECURITY ///////////////////////////////


// gets the ip address
export async function getIP() {
  const res =  await axios.get("https://api.ipify.org/?format=json");
  return res.data.ip
}


// create new guest
export async function createGuestUser () {
  const newGuestUser = {
    displayName: "guest",
    role: "subscriber",
    accessLevel: 0,
    loggedIn: false
  }
  return newGuestUser
}

export async function newSession () {
  return {
    loggedIn: false,
    user: 'guest',
    mode: 'guest',
    accessToken: null,
  }
}

export async function signedInSession () {
  return {
    loggedIn: true,
    mode: 'user',
    accessToken: 'something'
  }
}

export function sortDatesWithPastEventsLast(anArray) {

  let pastEvents = []
  let upcoming = []

  anArray.map((item)=> {
    if ( (new Date() - new Date(item.date)) > 0) {
      pastEvents.push(item)
    }  else {
      upcoming.push(item)
    }
  })

  const newArray = upcoming.concat(pastEvents)
  console.log(newArray)
  return newArray
}


//returns 'past', 'today', 'future'
export function compareEventDate(date) {
  // E.G., date = 'Wed May 01 2024 19:20:11 GMT-0500 (Central Daylight Time)'
  const event = new Date(date);
  const today = new Date();

  if (sameYear(event, today) && sameMonth(event, today) && sameDay(event, today)) {
    return 'Today';
  } else {
    if (event.getFullYear() < today.getFullYear()) {
      return 'Past';
    } else if (event.getFullYear() == today.getFullYear()) { // same year
        if (event.getMonth() < today.getMonth()) {
          return 'Past';
        } else if (event.getMonth() == today.getMonth()) { // same month
          if (event.getDate() < today.getDate()) {
            return 'Past';
          } else {
            return 'Future';
          }
        } else {
          return 'Future';
        } 
    } else {
      return 'Future';
    }
  }
}

function sameYear(date1, date2) {
  return date1.getFullYear() === date2.getFullYear();
}

function sameMonth(date1, date2) {
  return date1.getMonth() === date2.getMonth();
}

function sameDay(date1, date2) {
  return date1.getDate() === date2.getDate();
}
