import './App.css';
import React, { useState, useEffect, useContext } from 'react';


import AuthContext from './context/AuthProvider';
import ClientRoutes from './clientroutes'
import axios from 'axios';
import { createGuestUser, newSession } from './tools/main';
import ErrorPage from './components/errors/errorpage.component';
import { useNavigate } from 'react-router-dom';



const GET_USER_URL = '/user/auth'

function App() {

  const {auth, setAuth, session, setSession} = useContext(AuthContext)
  const [accessToken, setAccessToken] = useState(localStorage.getItem("accessToken"))
  
  //variables to check if app is mounted/loaded
  const [isMounted, didMount] = useState(false)
  const [loading, isLoading] = useState(true)
  const [guestUser, setGuestUser] = useState("")
  const [guestSession, setGuestSession] = useState('')
  const [errMsg, setErrMsg] = useState("")

  useEffect( async ()=> {

    // creates a new session
    console.log('here')
    //function which creates a new user guest user session
    const createGuestSession = async () => {
      const newSess = await newSession()
      const newGuest = await createGuestUser()
      setGuestUser(newGuest)
      setGuestSession(newSess)
    }
    //creates new session for visitors entering the website
    await createGuestSession() 

    // if accessToken is available, can authenticate a user
    const authenticateUser = async () => {
      try {
        const response = await axios.post(GET_USER_URL, { accessToken, refreshToken: "" })
        console.log(response)
        return response
        } catch(err) {
        //"Unable to authenticate user
        console.log(err)
        return false
      }}

    if (localStorage.getItem("accessToken") != null ) {
      authenticateUser().then(async (result)=>{
        // console.log(result)
        if (result.data.success == false) {
          //expired and/or invalid token
          // "unable to authenticate user")
          let newAuth = guestUser

          localStorage.setItem("loggedIn", false)
          localStorage.removeItem("accessToken")
          localStorage.removeItem("refreshToken")

          let newSess = await newSession()
          
          setAuth(newAuth)
          setSession(newSess)

        } else {
          let tempAuth = {...result.data.payload.user}
          tempAuth.loggedIn = true
          
          // console.log(tempAuth)
          setAuth(tempAuth)

          localStorage.setItem("loggedIn", true)
          setErrMsg("")
          
        }}).catch( (err) => {
            setErrMsg ("Sorry, we are not able to connect to our internal servers at the moment. Please try again later.")
            console.log("Error! Can not connect to db, to find user via accessToken.")
            window.location.replace('/errorpage')
          })

    } else {
      // No access token in local storage, but user is stilled logged in
      localStorage.setItem("loggedIn", false)
      
      setAuth(guestUser)
      setSession()
      
    }

    didMount(true)
    
  },[])

  useEffect(()=> {
    if (isMounted) {
        console.log('App is mounted')
        console.log('session-dta', session)
      }
  },[isMounted])
  // LOAD COMPONENTS ONLY WHEN AUTH IS ESTABLISHED
  useEffect(()=> {
    
    //when auth is updated/changed, page can be load
    if (isMounted && auth != null) {
      // console.log(auth)
      isLoading(false)
    } else if (isMounted && errMsg != "") {
      isLoading(false)

    }

  },[auth, isMounted, errMsg])


  if (!loading) {
    return (
      <>
        { errMsg != "" ? (<> < ErrorPage /> </>) : (<ClientRoutes/>)} 
      </>)
  }
}

export default App;
