
import React, { useState, useEffect} from 'react'

import { Form, Button, Container, Col, Row, Card, ProgressBar, Alert, InputGroup, Modal } from 'react-bootstrap'
import { ArrowRightSquare, BookFill } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom'
import { showPwd } from '../../../tools/main';
import axios from 'axios'
// @type {PaymentsClient}
// @private

const REGISTER_URL = '/user/register'
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W|_]).{8,}$/

function PasswordRequirnments () {

    return (
        <Alert variant="primary">
        <small>
            <ul>
                <li>The password must be at least 8 characters long</li>
                <li> The password must contain at least one lowercase letter, one uppercase letter, one digit, and one special character.</li>
            </ul>
        </small>
        </Alert>
    )
}
export default function Register() {
 
    const navigate = useNavigate()
    const [isMounted, didMount] = useState(false)
    const [loading, isLoading] = useState(true)
   
    const [progressStatus, setProgressStatus] = useState(3)

    //REGISTERATION INFORMATION
    const [prefix, setPrefix] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [fullName, setFullName] = useState('')
    const [validName, setValidName ] = useState(false)

    const [email, setEmail] = useState('')
    const [validEmail, setValidEmail] = useState(false)


    const [pwd, setPwd] = useState('')
    const [matchPwd, setMatchPwd] = useState('')
    const [pwdMatch, setPwdMatch] = useState(false)
    const [validPwd, setValidPwd] = useState(false)

    const [ isRegistered, setIsRegistered] = useState(false)

    const [errMsg, setErrMsg] = useState('')

    const [accountCreated, setAccountCreated] = useState(false)
    //cards

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const [membershipFocus, setMembershipFocus] = useState()
   
    
    //membership 
    const [membershipLevel, setMembershipLevel] = useState('')
    const [membershipName, setMembershipName] = useState() 
    const [membershipCost, setMembershipCost] = useState()
    const [address, setAddress] = useState({
        country: '',
        city: '',
        province: '',
        street: '',
        unit:'',
        postalCode: ''
      });


      const membership_levels = [{
        name: "Medical Professionals - $50/year",
        membershipType: "professional",
        description: "Includes physcians, nurses, and other medical professionals.",
        duration: "1",
        cost: "$50",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    },{
        name: "Students - $15/year",
        description: "Includes all learners such as undergraduates, medical students, medical residents, etc.",
        membershipType: "student",
        duration: "1",
        cost: "$15",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    }]

    useEffect(()=> {
        didMount(true)
    })
    useEffect(()=> {
        if (isMounted) { isLoading(false) }
    })


    useEffect(()=> {

        // check if pwd is valid
        if (pwd != '') {
            if (!PWD_REGEX.test(pwd)) {
                setErrMsg("Password is not valid")
                setValidPwd(false)
            } else {
                setErrMsg("")
                // if password is valid, check if they match
                // console.log('gere')
                if (pwd != '' && matchPwd != '') {
                    if (pwd == matchPwd) {
                        setPwdMatch(true)
                        setValidPwd(true)
                        setErrMsg('')
                    } else {
                        //pwds do not match
                        // console.log(pwd, matchPwd)
                        setPwdMatch(false)
                        setValidPwd(false)
                        setErrMsg("Passwords do not match")
                    }
                } 
                    else if (matchPwd=='') {
                        setPwdMatch(false)
                        setValidPwd(false)
                        setErrMsg("Passwords do not match")
                    }
            }
        } else {
            setErrMsg("")
        }


    }, [pwd, matchPwd])

    useEffect(()=> {
        if (email != ''){
            setValidEmail(true)
        } else {
            // setErrMsg("Please provide a valid email")
        }
    },[email])

    useEffect(()=> {
        
    },[])

    useEffect(()=> {

        if (firstName != '' | lastName == ''){
            setFullName(firstName + ' ' + lastName)
            setValidName(true)
        } else {
            setErrMsg("Provide a FIRST and LAST name to your account")
        }

    },[firstName, lastName])


    useEffect(()=> {
        if (isMounted && membershipLevel != '') {
            setMembershipName(membership_levels[parseInt(membershipLevel)-1].name)
            setMembershipCost(membership_levels[parseInt(membershipLevel)-1].cost)
        }
    }, [membershipLevel])


    useEffect(()=> {
        if (isRegistered) {
            setProgressStatus((progressStatus+25))
            // console.log("registeration complete")
        }

    }, [isRegistered])

    useEffect(()=> {
        // After account is successfully created
        if (accountCreated) {
            console.log('new users created')
            sessionStorage.setItem('registrationType', 'newUser')
        }
    },[accountCreated])

    const onSubmit= async (e) => {
     e.preventDefault()
       
    // define new user
    const newUser = { 
        email,
        pwd,
        firstName,
        lastName,
        prefix,
        displayName: firstName + ' ' + lastName,
    }
       
    // if pwd is valid 
    if (validPwd) {
        //send post request to add user
        try {
            await axios.post(REGISTER_URL, newUser)
                .then( (res) => {
                    const dta = res.data
                    // console.log(dta)
                    if (!dta.success) {
                        if (dta.message === 'An account already exists with this email.') {
                            //User with this email already exists
                            setErrMsg(dta.message)
                        } else {
                            setErrMsg('We are not able to retgister you at the moment. Please try again later.')
                        }
                        setAccountCreated(false)
                    } else {
                        // account was successfully created
                        setAccountCreated(true)
                        return dta
                    }
                }).catch(err => {
                    // console.log(err)
                    throw new Error('Internal Server error! Please try again later.')
                })

        } catch(err) {
            setErrMsg(err)
        }

        }
    }


    if (!loading){
        if (accountCreated == false) {
            return (
                <Container className='mt-150'>
                    <Form onSubmit={onSubmit}>
                    
                    <Row className="">
    
                        <Col></Col>

                        <Col xs={10} md={8} lg={6} >
    
                            <Card className='mt-2' style={{ }}>
                                <Card.Header> Register  </Card.Header>
    
                                <Card.Body style= {{paddingTop: "8px"}}>
                                    <Alert className={ errMsg != ''? ("mt-2 mr-5 ml-5 show"): ("hide")}style={{ marginRight: "5px", marginLeft: "5px" }} variant='danger'>
                                        {errMsg}
                                    </Alert>
                                    <Form.Group>
                                        <Form.Select aria-label="Default select example" onChange={e=> setPrefix(e.target.value)}>
                                            <option value="">Prefix</option>
                                            <option value="Mr"> Mr </option>
                                            <option value="Ms"> Ms  </option>
                                            <option value="Dr"> Dr </option>
                                        </Form.Select>
                                    </Form.Group>
                                    
                                    <Form.Group  className='form-group mt-3' >
                                                                           
                                        <Row>
                                            <Col>
                                                <Form.Control 
                                                type="text" 
                                                value={firstName}
                                                onChange={e=>setFirstName(e.target.value)}
                                                placeholder="First name" />
                                            </Col>
                                            <Col>
                                                <Form.Control 
                                                    type="text" 
                                                    value={lastName}
                                                    onChange={e=>setLastName(e.target.value)}
                                                    placeholder="Last name" />
                                            </Col>
                                        </Row>
    
                                    </Form.Group>
                                    
                                    <Form.Group  className='mt-3 form-group'>
                                       
                                        <Form.Control 
                                            type="email" 
                                            value-={email}
                                            placeholder="Enter your email"
                                            
                                            onChange={e=>setEmail(e.target.value)}
                                            />
     
                                        <Row className='mt-3 mb-3'>
                                            <Col>
                                                
                                                <Form.Control 
                                                    type="password" 
                                                    placeholder="Enter your password" 
                                                    id='password'
                                                    value={pwd}
                                                    onChange={e=>setPwd(e.target.value)}
                                                    
                                                />

                                                <InputGroup className="mt-2">
                                                    <Form.Check 
                                                        type= "checkbox"
                                                        id= "pwd-show"
                                                        label= "Show password"
                                                        onClick={ e => showPwd (['password', 'password2'])}
                                                    />
                                                </InputGroup>
                                            </Col>
    
                                            <Col>
                                                <Form.Control 
                                                    type="password" 
                                                    id='password2'
                                                    placeholder="Confirm password" 
                                                    value={matchPwd}
                                                    onChange={e=>setMatchPwd(e.target.value)}/>
                                                </Col>
                                        </Row>
                                        
                                        <div className= { !validPwd? ("show"): ("hide")} >
                                            < PasswordRequirnments />
                                        </div>
                                        
                                    </Form.Group>
                                <div style={{ textAlign: "center"}}> 
                                    <Button 
                                        variant="warning" 
                                        className="btn-reg-form" 
                                        type='submit' 
                                        style= {{width: "12rem"}} >
                                        Register
                                    </Button>
                                </div>     
                            </Card.Body>

                        </Card>
                        </Col>
                        
                        <Col></Col>
                    
    
                    </Row>

                </Form>
            </Container>
    
        )
             } else {
                return (
                    <Container>
                        <Row style={{ marginTop: "15vh"}}>
                            <Col></Col>
                            <Col xs={10} sm={4}>
                                <Card style={{ }}>
                                    <Card.Body>
                                        Congratulations! You have successfully created an account. To ensure the security of your account, 
                                        we kindly request that you verify your account upon your first login attempt. 
                                        This step is essential for account activation and protecting your account information.
                                        <br/>
                                        <br />
                                        <a href='/login'> Click here</a> to log into your account.
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col></Col>
                        </Row>
                    </Container>
                )
             }

}


}