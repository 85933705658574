import React, { useEffect, useState, useContext } from 'react';
import { Container, DropdownButton, ButtonGroup, Row, Col, Card, Button, Nav, NavDropdown, Modal, Image, ListGroup, Navbar, Form, FormGroup, Alert, Dropdown, Badge } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../context/AuthProvider';
import axios from 'axios';
import { Check2Circle } from 'react-bootstrap-icons';
import { displayText, splitParagraphs, strToDateObject } from '../../tools/main';


function OpportunityCard ({item, handleDelete, auth}) {
  const [collapse, handleCollapse] = useState(false)
  
  useEffect(()=> {
    console.log(collapse)
  }, [collapse])

  // if opp target is members only
  return (
    <ListGroup.Item style={{border: "none", padding: "0px", paddingBottom: "5px"}}>
      <Card className='opp-card' >
        <Card.Body >
          <div>
            <p style={{ fontStyle: "italic", fontSize: "small", marginBottom: "2px"}}>
              {item.category == 'Research'? ("Research&Funding"):(item.category)} 
              <span className= {item.target_audience == 'members' && auth.membershipstatus == 'Active'? ('show'):('hide')} style={{ display: "inline-block"}}>
              &#183;
                <Badge  bg="secondary">
                  Member's only 
                </Badge> 
              </span>


            
            <span style={{ float: "right"}}> {strToDateObject(item.date_created).toDateString()} </span>

            </p>
          </div>
          <Card.Title> { item.title } </Card.Title>
          <Card.Text  className={ !collapse? 'opp-text': 'opp-text show-more '} >
            <div>
            { splitParagraphs(item.description).map(para => {
              return (
                <> {para} <br/> </>
              )
            }) }
            </div>
            
          </Card.Text>
          
          <div style={{ position: "absolute", bottom:"10px", width: "100%", paddingRight: "25px" }}>
            <Button
            variant='outline-primary'
            style={{ }}
            onClick={e => handleCollapse(!collapse)}> 
              {!collapse? 'Read more':'Show less'}
            </Button> 
            { auth.accesslevel == 9  &&(<Button 
              variant='outline-danger'
              id = {item.opp_id}
              onClick={e=> handleDelete(e)}
              style={{ float: "inline-end", width: "100px"}}
              > Delete 
              </Button>) }
          </div>

        </Card.Body>
      </Card>
    </ListGroup.Item>
  )
}
export default function OpportunitiesPage() {
    const { auth } = useContext(AuthContext);
    const [categories, setCategories] = useState([]);
    const [opportunites, setOpportunities] = useState([])
    // const [listing, showListing] = useState([])
    const [filter, setFilter] = useState("")
    const [mounted, isMounted] = useState(false)
    const [loading, setLoading] = useState(true);
    const [count, setCount] = useState(0)
    const [showEventForm, setShowEventForm] = useState(false)
    const [formComplete, setFormComplete] = useState(false)
    const [newForm, setNewForm] = useState(0)
    const [submissionLoading, loadSubmission] = useState(false)

    useEffect(() => {
        async function fetchData() {
          return await axios.get('/opportunities/all')
            .then(res=> {
              console.log(res)
              return res.data
            }).catch(err => {
              throw Error ('Can not fetch data')
            })

        }

        fetchData().then(res=>{
          console.log(res)
          if (!res.success) {
            throw Error ('Erro!')
          } else {
            setOpportunities(res.opportunities.reverse())
          }
        }).catch(err=> {
          console.log(err)
        });

        isMounted(true)

    }, [count]);

    useEffect(()=> {
      if (mounted) setLoading(false)
    },[mounted])
    useEffect(()=> {
      console.log(opportunites)
    },[opportunites])
    useEffect(()=>{
      setFormComplete(false)
      setShowEventForm(false)
    },[newForm])


    useEffect(()=> {
      if (formComplete == true) {
        setTimeout(()=>{
          setNewForm(newForm+1)
          setCount(count+1)
        },2000)
      }
    }, [formComplete])

    const handleDelete = async (e) => {
      e.preventDefault()
      const key = e.target.id

      await axios.delete("/opportunities/delete/"+key)
        .then(res=> {
          if (res.data.success) {
            setCount(count+1)
          }
        }).catch(err => {
          console.log(err)
        })
    }
    const handleFormSubmit = async (data) => {
        loadSubmission(true)
        // Handle the form submission here, for example, send the data to a server or update state.
        data['author'] = auth.email
        //1. send data to back end
        const response = await axios.post('/opportunities/new', data)
          .then((result)=>{
            if (result.data.success) {
              // reload component to update list and clear data
              setFormComplete(true)
              // clear previous inputs
              return true
            } else {
              throw Error('Error!')
            }
          }).catch((err)=>{
            console.log(err)
            return false
          })
        
        return response
      };

    if (!loading) {
        return (
            <>
            
              <Row className="mt-100">
                <Col></Col>
                <Col sm={10}>
                    <div>
                      { auth.accesslevel == 9? (
                      
                      <Button 
                        style={{ marginRight: "10px"}}
                        disabled = {formComplete ? true: false} 
                        variant = 'outline-primary' 
                        onClick = {(e)=>{ !formComplete && setShowEventForm(!showEventForm) } }  
                        >New Opportunity</Button>):(<></>)}
                      
                      <DropdownButton 
                        as={ButtonGroup} 
                        title={'Filter'} 
                        style={{ color: "black", paddingRight: "10px", minWidth: "100px"}}

                        variant={"outline-primary"}
                        key = "1"
                        onSelect={(event)=> {setFilter(event)}}
                      >
                        <Dropdown.Item eventKey={""}>All</Dropdown.Item>
                        <Dropdown.Item eventKey={"General"}>General</Dropdown.Item>
                        <Dropdown.Item eventKey={"Conference"}>Conference</Dropdown.Item>
                        <Dropdown.Item eventKey={"Volunteer"}>Volunteer</Dropdown.Item>
                        <Dropdown.Item eventKey={"Research"}>Research & Funding </Dropdown.Item>
                        <Dropdown.Item eventKey={"Awards&Scholarships"}>Awards & Scholarships</Dropdown.Item>
                        <Dropdown.Item eventKey={"Employment"}>Employment</Dropdown.Item>
                      </DropdownButton>
                    </div>

                    <div>
                        <Card className={showEventForm? "show mt-10":"hide"}>
                            
                              {formComplete == false? (
                                <Card.Body> 
                                  <EventForm onSubmit={handleFormSubmit} /> 
                                </Card.Body>):(
                                <Alert variant='success' style={{ height: "inherit", margin: "0px"}}>
                                  Succussfully submited. <Check2Circle />
                                </Alert>)
                              }
                        </Card>
                    </div>
                      
                    <div className='mt-10'>
                      <ListGroup>
                      { opportunites.map((item, index) => {
                        
                        console.log('here')
                        if (item.target_audience != 'members') {
                          if (filter == item.category) {
                            return (<OpportunityCard item={item} handleDelete={handleDelete} auth = {auth} />)
                          } else if (filter == "") {
                            return (<OpportunityCard item={item} handleDelete={handleDelete} auth = {auth} />)
                          }
                        } else {
                          if (filter == item.category && auth.membershipstatus == 'Active') {
                            return (<OpportunityCard item={item} handleDelete={handleDelete} auth = {auth} />)
                          } else if (filter == "" && auth.membershipstatus == 'Active') {
                            return (<OpportunityCard item={item} handleDelete={handleDelete} auth = {auth} />)
                          }
                        }


                      })}
                      </ListGroup>
                    </div>
                </Col>
                <Col></Col>
            </Row>

          </>

        );
    }

    return null;
}



const EventForm = ({ onSubmit }) => {
    const [loading, isLoading] = useState(false)
    const [item, setItem] = useState({
        title: '',
        description: '',
        category: '',
        author: ''
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setItem((prevItem) => ({ ...prevItem, [name]: value }));
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();

        const success = await onSubmit(item);
        if (success) {
          setItem({
            title: '',
            description: '',
            category: 'General',
            target_audience: '',
            author: ''
          })
        } else {
          console.log(success, 'Unable to submit Form')
        }
        // You can add additional logic here, such as clearing the form or navigating to another page.
      };
    return (
      <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formTitle" style={{ paddingBottom: "0.6rem"}}>
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter title"
              name="title"
              value={item.title}
              onChange={handleChange}
            />
          </Form.Group>

          <Row>
            <Col>
              <Form.Group style={{ paddingBottom: "0.6rem"}}>
                <Form.Select 
                  aria-label='Category' 
                  className='mt-2' 
                  name="category"
                  onChange={handleChange}>
                  <option > Category </option>
                  <option value ='General'>General</option>
                  <option value ='Volunteer'>Volunteer</option>
                  <option value ='Conference'>Conference</option>
                  <option value ='Research'>Research Opportunities & Funding </option>
                  <option value ='Awards&Scholarships'>Awards & Scholarships</option>
                  <option value ='Employment'>Employment</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group style={{ paddingBottom: "0.6rem"}}>
                  <Form.Select 
                    aria-label='TargetAudience' 
                    className='mt-2' 
                    name="target_audience"
                    onChange={handleChange}>
                    <option > Target Audience </option>
                    <option value ='public'>Public</option>
                    <option value ='members'>Members only</option>
                    <option value ='cstars'>CStaRS Members</option>
                  </Form.Select>
              </Form.Group>
            </Col>
          </Row>


          <Form.Group controlId="formText" style={{ paddingBottom: "0.6rem"}}>
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={8}
              placeholder="Enter description (include relevant urls, links, etc.)"
              name="description"
              value={item.description}
              onChange={handleChange}
            />
          </Form.Group>
          <Button 
            variant="primary" 
            type="submit" 
            className='mt-2' 
            >
            Submit
          </Button>
        </Form>
      );
}
