
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Image, Accordion } from 'react-bootstrap';


export default function FAQPage() {
    const [isMounted, didMount] = useState(false)
    const [loading, isLoading] = useState(true)

    useEffect(()=>{
        didMount(true)
    }, [])

    useEffect(()=>{
        if (isMounted) {
            isLoading(false)
        }
    }, [isMounted])

    if (!loading) return(
        <Container className='mt-100'>
            <Row>
                <Col></Col>
                <Col xs={10} sm={8} lg={6}>
                <div>
                    <h2> Frequently Asked Questions (FAQ) </h2>
                    <Card className='mt-2'>
                        <Card.Body>
                        <Card.Title>1. How do I join CSAM/CStaRS?</Card.Title>
                        <Card.Text>
                            Head over to our Registration page at <a href="https://csam-scma.ca/register" target="_blank" rel="noopener noreferrer">https://csam-scma.ca/register</a>. You’ll be prompted to fill in your name, email, and password, and select one of two membership tiers: (i) Professional/General, or (ii) Student.
                        </Card.Text>
                        </Card.Body>
                    </Card>

                    <Card className='mt-2'>
                        <Card.Body>
                        <Card.Title>2. What are the benefits of joining?</Card.Title>
                        <Card.Text>
                            Astronomical! These include:<br />
                            <ul>
                                <li>Access to online electronic journals and other resources to stay informed on the latest developments in aerospace medicine </li>
                                <li>Discounted rates for CSAM annual conferences and other events</li>
                                <li>Opportunities to volunteer with CSAM and contribute to the advancement of the field</li>
                                <li>Participation in the CSAM board of directors elections</li>
                                <li>Eligibility to participate in CSAM elections for leadership positions</li>
                            </ul>
                            Plus many more opportunities and resources to advance your career, network, and keep up to date with the latest in aerospace medicine!
                        </Card.Text>
                        </Card.Body>
                    </Card>

                    <Card className='mt-2'>
                        <Card.Body>
                        <Card.Title>3. Who will I be able to connect with?</Card.Title>
                        <Card.Text>
                            As a CSAM/CStaRS member, you’ll be able to connect with professionals in the aerospace medicine community from industry to academia, along with students and trainees across all stages of their journeys from undergraduate studies, graduate studies, medical school, residency, and fellowship.
                        </Card.Text>
                        </Card.Body>
                    </Card>

                    <Card className='mt-2'>
                        <Card.Body>
                        <Card.Title>4. What events does the organization run?</Card.Title>
                        <Card.Text>
                            CSAM/CStaRS runs events aimed at connecting the aerospace community from professionals, to students, and to the general public aerospace community enthusiasts. Some of our past events have included:<br />
                            <ul>
                                <li>Conferences</li>
                                <li>Guest speakers</li>
                                <li>Speaker panels</li>
                                <li>Multidisciplinary events with Wilderness Medicine, Dive Medicine, or Aerospace Engineering</li>
                            </ul>
                            Check out our upcoming <a href="/events" target="_blank" rel="noopener noreferrer"> events! </a>
                        </Card.Text>
                        </Card.Body>
                    </Card>
                    </div>
                </Col>
                <Col></Col>
            </Row>
        </Container>
    )
}