import React, { useState, useEffect, useContext} from 'react'
import { Form, Button, Container, Col, Row, Card, ProgressBar, Alert, InputGroup, Modal } from 'react-bootstrap'
import { CartCheck } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom'

import CSAM_LOGO from '../../../images/csam-logo.jpeg'

// @type {PaymentsClient}
// @private
import AuthContext from '../../../context/AuthProvider';
const REGISTER_URL = '/user/register'




export default function SignupMembership() {
    const {auth, setAuth} = useContext(AuthContext)
    const navigate = useNavigate()
    const [isMounted, didMount] = useState(false)
    const [loading, isLoading] = useState(true)
    const [mode, setMode] = useState('')

    const [membershipFormComplete, setMembershipFormComplete] = useState(false)
    

    const [errMsg, setErrMsg] = useState('')
    const [isERROR, setError] = useState(false)

    //cards
    const [itemSelected, selectedItem ] = useState(0)
    
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const [membershipFocus, setMembershipFocus] = useState()
    const handleShow = (e, index) => {
        e.preventDefault()
        setShow(true)
    }


    //user information
    const [occupation, setOccupation] = useState('')
    const [otherQualifications, setOtherQualifications] = useState('')
    const [address, setAddress] = useState({
        country: '',
        city: '',
        province: '',
        street1: '',
        street2:'',
        postalCode: ''
      });
    const [addressFilled, setAddressFilled] = useState(false)
    

    

    const membership_levels = [
        {
            id: 1,
            name: "Students - $15/year",
            description: "Includes all learners such as undergraduates, medical students, medical residents, etc.",
            membershipType: "student",
            duration: "1",
            cost: "$15",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        },
        {
            id: 2,
            name: "Professionals - $50/year",
            membershipType: "professional",
            description: "Includes physcians, nurses, and other medical professionals.",
            duration: "1",
            cost: "$50",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        }
]

    useEffect(()=> {
        // if user already a member route to user account page
        console.log(auth)
        if (auth.ismember == 'true') { // is or was a member

            if ( auth.membership_is_expired == 'true') {
                setMode('Membership Renewal in Grace Period')
                didMount(true)
            } else {
                navigate('/myaccount')
            }
        } else {
            setMode('New member')
            didMount(true)
        }
        
    },[])

    useEffect(()=> {

        if (isMounted) {
            isLoading(false)
        }
    },[isMounted])

    useEffect(()=> {

        if (itemSelected != 0 && !loading) {

            
            for ( var item of membership_levels) {
                // console.log(item.id, itemSelected, loading)
                const el = document.getElementById(item.id)

                if (item.id == itemSelected) {
                    el.classList.add("active-card")
                } else {
                    el?.classList.remove("active-card")
                }
            }
        
        
        }
    }, [itemSelected, loading])

    // check if all the fields are field, before reviewing info
    useEffect(()=> {
        //console.log(address)
        //check if address correctly filled
        if (address.country != '' && address.city != '' && address.province != '' && address.street1 != '' && address.postalCode != '') {
            setAddressFilled(true)
        } else {
            setAddressFilled(false)
        }

        // console.log(occupation, itemSelected)
        if (occupation == "Student"){
                selectedItem(1)
        } else if (occupation != '') {
                selectedItem(2)
            }
        


    }, [address.country, address.city, address, occupation, itemSelected])

    useEffect(()=> {
        
        //ready to review results
        if (addressFilled && occupation != '') {
            console.log(address)
            setMembershipFormComplete(true)
            setErrMsg('')
        } else {
            
            setErrMsg("Please fill the required fields.")
            setMembershipFormComplete(false)
        }

    }, [addressFilled, occupation])


    useEffect(()=> {
        // console.log(errMsg)
        if (errMsg == '') {
            setError(false)
        } else {
            setError(true)
        }
    },[errMsg])

    const handleCheckout = (e) => {
        e.preventDefault()

        if (membershipFormComplete) {
            fetch("/user/create-checkout-session", {

                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    user: auth,

                    address,
                    items: [
                        {
                            id: itemSelected, quantity: 1
                        }
                    ]
                    })
            }).then(res => {
    
                if (res.ok) return res.json()
    
                // if response is not okay (failure)
                return res.json().then(json => Promise.reject(json))
    
            } ).then( ({url}) => {

                console.log(url)
                window.location = url 
    
            }).catch(e=> {

                console.error(e)
            })
        }




    }

    const onSubmit= async (e) => {

       e.preventDefault()
       navigate("/login")
    }

    
    if (!loading){
    return (
        <Container className='mt-100 mb-5'>
            <Form onSubmit={onSubmit}>
                <Row className="mt-2">
                    <Col></Col>
                    <Col xs={10} md={8}>
                        <Card className='mt-2'>
                            <Card.Header style={{ fontWeight: "600"}}> UPDATE USER INFORMATION </Card.Header>
                            <Card.Body>
                                {
                                    errMsg != '' ? (
                                        <Alert variant='danger' className="mt-2" >
                                            { errMsg }
                                        </Alert>
                                    ):(<></>)
                                }

                                <Form.Group className='form-group'>
                                    <Form.Label>Address</Form.Label>

                                    <Row className="mt-1">
                                        <Col><Form.Control type="text" value={address.street1} onChange={e=> {setAddress({...address, street1: e.target.value})}} placeholder='*Address Line 1'/></Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col><Form.Control type="text" value={address.street2} onChange={e=> {setAddress({...address, street2: e.target.value})}} placeholder='Address Line 2 (e.g., Apartment unit, suite, floor, building,department numbers, or PO Boxes.)'/></Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col><Form.Control type="text" value={address.city} onChange={e=> {setAddress({...address, city: e.target.value})}} placeholder='*City'/></Col>
                                        <Col><Form.Control type="text" value={address.province} onChange={e=> {setAddress({...address, province: e.target.value})}}  placeholder='*Province/State'/></Col>

                                    </Row>
                                    <Row className="mt-2">
                                        <Col><Form.Control type="text" value={address.country} onChange={e=> {setAddress({...address, country:e.target.value})}} placeholder='*Country'/></Col>
                                        <Col><Form.Control type="text" value={address.postalCode} onChange={e=> {setAddress({...address, postalCode: e.target.value})}} placeholder='*Postal/Zip Code'/></Col>
                                    </Row>
                                    
                                    
                                </Form.Group>
                                
                                <Row className="mt-3"> 
                                    <Col sm={12} md={6} >
                                        <Form.Label> What is your current background/occupation ? </Form.Label>
                                        <Form.Group className='form-group'>
                                            <Form.Select
                                                value={occupation}
                                                onChange={ e => { {setOccupation(e.target.value)}}}
                                            >
                                                <option value=""> Select from below </option>
                                                <option value="Student"> Student </option>
                                                <option value="MD"> Medical Professional </option>
                                                <option value="Nurse"> Nurse </option>
                                                <option value="Engineer"> Engineer </option>
                                                <option value="Other"> Other </option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <Form.Group className='form-group'>
                                        <Form.Label> Any qualifications/interests you would like to share? (Optional) </Form.Label>
                                        <Form.Control 
                                            type="text"
                                            value={otherQualifications}
                                            onChange={e=> {setOtherQualifications(e.target.value)}}
                                        />
                                        </Form.Group>
                                    </Col>
                                </Row>

                            </Card.Body>

                            <Card.Footer style= {{ textAlign: "center" }}>
                                <Button variant="warning" className="" style= {{ borderRadius: "0%"}} onClick={e=> { handleCheckout(e) }}>
                                    Complete Checkout and Pay <CartCheck /> 
                                </Button>
                            </Card.Footer>

                        </Card>
                    </Col>
                    <Col></Col> 
                 </Row>

                 <Row>
                    <Col></Col>
                    <Col xs={10} md={8}>
                        <Card className='mt-3'>
                            <Card.Body>
                                <Row>
                                    <small style={{ fontStyle: "italic"}}> *Membership tier is determined based on your selected background/occupation. </small>

                                
                            {membership_levels.map((item, index)=> {
                                        
                                        return(
                                            <Col sm= {6} md={4}>
                                                <Card id={item.id} className= "mt-3 membership-item">
                                                    <Card.Img variant="top" src={CSAM_LOGO} />
                                                    <Card.Body>
                                                        <Card.Title style={{ fontSize: "18px"}}>
                                                            {item.name} 
                                                        </Card.Title>
                                                        <Card.Text>
                                                            <strong> Price: </strong> {item.cost} annually
                                                        </Card.Text>
                                                        <div style={{textAlign: "center"}}>
                                                            {/* <p> <Button style={{ width: "150px", maxWidth: "90%" }} variant="warning" onClick={(e)=>{setMembershipFocus( membership_levels[index]); handleShow(e, index)}}> Details </Button> </p> */}
                                                             {/* <p><Button style={{ width: "150px",  maxWidth: "90%" }} variant="warning" > Add </Button> </p> */}
                                                            
                                                        </div>
                                                    </Card.Body>
                                                </Card>
   
                                            </Col>
                                        )
                                    })}

                 </Row>
                    <Modal show={show} onHide={handleClose} animation={false}>
                                            <Modal.Header closeButton>
                                                <Modal.Title> {membershipFocus?.name}</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <h6> Membership Details </h6>
                                                <p>
                                                    {membershipFocus?.description}
                                                </p>
                                                
                                                <p>
                                                    <strong> Price: </strong> {membershipFocus?.cost} annually
                                                </p>
                                                
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={handleClose}>
                                                        Close
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col> </Col>
                </Row> 
            </Form>

        </Container>

    )}


}