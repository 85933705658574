import axios from 'axios'
import React, { useState, useEffect, useContext} from 'react'

import { Form, Button, Container, Col, Row, Card, ProgressBar, Alert, InputGroup, Modal } from 'react-bootstrap'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

// @type {PaymentsClient}
// @private
import AuthContext from '../../../context/AuthProvider';
const REGISTER_URL = 'http://localhost:8000/user/register'


export default function OrderRecieptSuccess() {
    const {auth, setAuth} = useContext(AuthContext)
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const [isMounted, didMount] = useState(false)
    const [loading, isLoading] = useState(true)

    const [errMsg, setErrMsg] = useState('')
    const [isERROR, setError] = useState(false)


    useEffect(()=> {
        
        const sess_id = searchParams.get('session_id')

        console.log(sess_id)

        axios.post('/user/order/success', { 
            session_id: sess_id,
            user: auth,
            accessToken: localStorage.getItem('accessToken')
        })
        .then(response => {
            const data = response.data

            console.log(data)

            if (data.membership_updated) {
                if (data?.newAccessToken) {
                    localStorage.setItem("accessToken", data.newAccessToken)
                
                } else {
                    console.log("no new token")
                }
            }

            didMount(true)

        }).catch(err => {
            // handles 
            console.log(err.response)
            setErrMsg(err.response.data.message)
            
        })

      


    },[])

    useEffect(()=> {
        isLoading(false)
    },[isMounted])
    
    if (!loading){
    return (
        <>
        <Container className='mt-150'>
            <Row>
                    <Col></Col>
                    <Col xs={8}>
                        
                            
                            { errMsg != '' ? (<Alert variant='danger'> { errMsg }</Alert>): 
                                            (
                                            <Card>
                                                <Card.Body bg="">           
                                                    <p>
                                                        Congratulations <strong> { auth.displayName } </strong> for starting your new membership!
                                                    </p>

                                                    <p>
                                                            Return to <a href="/myaccount"> Your Account </a>.
                                                    </p>
                                                </Card.Body>
                                            </Card>
                                            )
                        }
                            
                        
                    </Col>
                    <Col></Col>
            </Row>
            </Container>
        </>
 

    )}


}