import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import CSAM_LOGO from '../images/csam-logo.jpeg'

function HTMLStringToElement({ htmlString }) {
    return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
  }

export default function MembershipInfo() {
    const navigate = useNavigate()
    const [show, setShow] = useState(false);
    

    const [membershipFocus, setMembershipFocus] = useState()
   
    const handleClose = () => setShow(false);
    const handleShow = (e, index) => {
        e.preventDefault()
    
        setShow(true)

    }

    const membership_levels = [{
        name: "Professional/General",
        membershipType: "professional",
        short_description: "Includes physcians, nurses, and other medical professionals.",
        duration: "1 year",
        amount: "$50/year",
        curreny: "CAD",
        long_description: "<ul> <li>Attend Annual General Meetings: As a professional member, you gain exclusive access to attend the organization's annual general meetings. These meetings provide valuable opportunities to stay updated on industry trends, discuss important matters, and contribute to the direction of the organization. </li> <li> Join the Organization's Slack: By becoming a member, you gain entry into the organization's Slack community, a dedicated platform where members can freely interact and network with one another. This virtual space offers an excellent avenue for collaboration, sharing knowledge, and forging valuable professional connections.</li> <li> Engage in Organization Elections: As a professional member, you have the privilege to actively participate in the organization's elections. Your vote and involvement contribute to shaping the leadership and decision-making processes within the organization. </li> <li> Exclusive Website Access: Professional members enjoy a designated area on the organization's website, granting them access to a directory of fellow members. This directory serves as a valuable resource, allowing you to connect with like-minded professionals, explore potential collaborations, and expand your network. </li> <li> Exchange Ideas with International Aerospace Medicine Experts: Being a professional member opens up the opportunity to engage in knowledge-sharing with esteemed experts in the field of Aerospace Medicine from around the world. Through conferences, webinars, and other collaborative platforms, you can exchange ideas, gain insights, and stay updated on the latest advancements in your specialized domain.</li> </ul>" 
    },{
        name: "Student",
        short_description: "Includes all learners such as undergraduates, medical students, medical residents, etc.",
        membershipType: "student",
        duration: "1 year",
        amount: "$15/year",
        curreny: "CAD",
        long_description: "<ul> <li> Access to Organization Slack: By being a part of the student membership, individuals can join the organization's Slack channel. This exclusive platform allows members to freely interact and network with fellow students, professionals, and mentors in the field of aerospace medicine, fostering valuable connections and collaborations.</li> <li> Participation in CSTARS Organization Elections: As student members, individuals have the right to actively participate in the elections held by the CSTARS organization. This allows them to have a voice in shaping the organization's direction and leadership, fostering a sense of ownership and involvement.</li> <li> Exclusive Member Directory on the Website: The organization's website offers a dedicated section exclusively accessible to student members. Within this section, members can access a directory of fellow student members, facilitating connections and collaborations among like-minded individuals. </li><li> Attendance at Annual General Meetings: Student members gain the privilege of participating in Annual General Meetings, providing them with valuable opportunities to engage, contribute, and stay updated on the organization's activities and developments.</li> <li> Networking with Aerospace Medicine Mentors: Student members have the unique advantage of networking with experienced mentors specializing in aerospace medicine. These connections can offer guidance, support, and valuable insights into the industry, helping students broaden their professional horizons.</li> <li> Learning Opportunities in Aerospace Medicine: Student members gain access to a wealth of information about learning opportunities within the field of aerospace medicine. This includes updates on workshops, conferences, webinars, and other educational resources, enabling students to enhance their knowledge and skills in their chosen area of study.</li>  </ul>" 
    }]
    


    useEffect(()=> {
        console.log(membershipFocus)
    }, [membershipFocus])

    const stringToElement = (string) => {
        var div = document.createElement('div');
        div.innerHTML = string.trim()
        
        return div.firstChild;

    }

    return(
        <Container className='mt-80'>
            <Row>
                <Col></Col>
                <Col xs={12} md={8} className="mt-5" >
                    <Card>
                        <Card.Header> Membership Information </Card.Header>
                        <Card.Body>
                        
                        <h6><strong>  Why become a CSAM member?  </strong></h6>
                            <p>As a leading organization representing aerospace medicine professionals in Canada, the Canadian Society of Aerospace Medicine (CSAM) offers a range of benefits to its members. By joining the CSAM community, you will have access to a wealth of resources and opportunities to advance your career, network with other professionals in the field, and stay informed on the latest developments in aerospace medicine.</p>
                            <p>Some of the perks of being a CSAM member include:</p>
                            <ul>
                            <li>Access to online electronic journals and other resources to stay informed on the latest developments in aerospace medicine</li>
                            <li>Discounted rates for CSAM annual conferences and other events, providing valuable opportunities for professional development and networking</li>
                            <li>Participation in the CSAM board of directors elections, giving you a voice in the direction and leadership of the organization</li>
                            <li>Opportunities to volunteer with CSAM and contribute to the advancement of the field</li>
                            <li>Eligibility to participate in CSAM elections for leadership positions</li>
                            </ul>
                            <p>Don't miss out on the chance to be a part of Canada's premier organization for aerospace medicine professionals. Sign up for a CSAM membership today and take advantage of the many benefits it has to offer.</p>
                        
                        <h6><strong> Membership Tiers  </strong></h6>
                            <div>
                                <Row>
                                    {membership_levels.map((membership, index)=> {
                                        return(
                                            <Col sm= {6} md={4}>
                                                <Card className='mt-3 h100' style={{}}>
                                                    <Card.Img style={{ }}variant="top" src={CSAM_LOGO} />
                                                    <Card.Body>
                                                        <Card.Title style={{ fontSize: "16px"}}>
                                                            Membership: {membership.name} 
                                                        </Card.Title>
                                                        <Card.Text>
                                                            <strong> Price: </strong> {membership.amount}
                                                        </Card.Text>
                                                        <div style={{textAlign: "center"}}>
                                                            <p> <Button style={{ width: "150px", maxWidth: "90%" }} variant="warning" onClick={(e)=>{setMembershipFocus( membership_levels[index]); handleShow(e, index)}}> Details </Button> </p>
                                                             <p><Button style={{ width: "150px",  maxWidth: "90%" }} variant="warning" onClick={(e=>{navigate('/signup-membership')})}> Add </Button> </p>
                                                            
                                                        </div>

                                                    </Card.Body>
                                                </Card>
   
                                            </Col>
                                        )
                                    })}

                                        <Modal show={show} onHide={handleClose} animation={false}>
                                            <Modal.Header closeButton>
                                                <Modal.Title> {membershipFocus?.name}</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <h6> Membership Details </h6>
                                                <p> 
                                                    <HTMLStringToElement htmlString={ membershipFocus?.long_description} />
                                                </p>
                                                
                                                <p>
                                                    <strong> Price: </strong> {membershipFocus?.amount} annually
                                                </p>
                                                
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={handleClose}>
                                                        Close
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                </Row>
                            </div>
                        </Card.Body>
                        <Card.Footer style={{ textAlign: "center"}} >
                            <Button variant="warning" onClick={(e=>{navigate('/signup-membership')})}>
                                BECOME A MEMBER
                            </Button>
                        </Card.Footer>
                    </Card>


                </Col>
                <Col></Col>
            </Row>
        </Container>
    )
}