import React, { useContext, useEffect, useState } from 'react';
import { Form, Button, Card, Col, Container, Nav, Navbar, Row, Image } from 'react-bootstrap';
import { Instagram, Linkedin, Slack } from 'react-bootstrap-icons';
import EmbedSlack from '../widgets/slack/slack.widget.component';
import PhotoAlbum from "react-photo-album";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from 'swiper';
import AuthContext from '../../context/AuthProvider';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// import * as photos from "./cstars-gallery"
export function Gallery() {
    const [item, setItem] = useState({})
    
    const photos = [
        {src:  window.location.origin + '/event/images/7.jpeg', width: 700, height: 650},
        {src:  window.location.origin + '/event/images/8.png', width: 800, height: 700},
        {src: window.location.origin + '/event/images/1.png', width: 800, height: 600},
        {src:  window.location.origin + '/event/images/2.jpg', width: 800, height: 600},
        {src:  window.location.origin + '/event/images/3.jpg', width: 800, height: 600},
        {src:  window.location.origin + '/event/images/4.jpg', width: 800, height: 600},
        {src:  window.location.origin + '/event/images/5.jpg', width: 800, height: 500},
        {src:  window.location.origin + '/event/images/6.jpg', width: 900, height: 450}

    ]


    return (
        <Card className='mt-3' style={{ border: "none", height: "360px"}}>
            <h3> Gallery </h3>
            {/* <Gallery photos={photos} /> */}

            <Swiper 
                modules={[Pagination, Navigation, Autoplay]}
                
                slidesPerView={"auto"}
                centeredSlides = {true}
                spaceBetween={30}
                grabCursor={true}
                autoplay={{ delay: 3000, disableOnInteraction: false }}

            >
                {photos.map(item=> {
                    return (
                        <SwiperSlide>
                            
                            <Image src={item.src} height= "100%" width="auto" />
                        </SwiperSlide>
                    )
                })}
            </Swiper>
            
        </Card>

        
    )
}


export function MainBodyCSTARS() {
    const navigate = useNavigate()
    const {auth, setAuth} = useContext(AuthContext)


    const intro_cards = [
        {
            title: "CStaRS",
            text: "Student-led subgroup of the Canadian Society for Aerospace Medicine (CSAM). ",
        },        {
            title: "Centralize",
            text: "A hub for research and educational opportunities to develop and expand aerospace medicine across Canada.",
            img: "",
        },        {
            title: "Accessibility",
            text: "Improving accessibility of aerospace medicine opportunities more to Canadian students and residents.",
            img: "",
        },        {
            title: "Membership",                 
            text: "CStaRS members will have access to unique opportunities such as research and mentorship programs.",
            img: "",
        }
    ]
    const relocateTo = (e) => {
        e.preventDefault()
        const id = e.target.id;
        if ( id == "linkedin") {
            window.location.href = "https://www.linkedin.com/company/canadian-society-for-aerospace-medicine-student-resident-section-cstars/"   
        } else if (id == "instagram") {
            window.location.href = "https://www.instagram.com/csam_scma/"
        }
    }
    

    return (
        <div> 
            <Row className = "mt-3 mb-3">
                {intro_cards.map( (point)=> {
                return (
                    <Col xs={8} sm={3}>
                        <Card className = 'cstar-intro-cards'>
                            <Card.Body className='mx-auto'>
                                <Card.Title> {point.title} </Card.Title>
                                <Card.Text> {point.text}</Card.Text>

                                {
                                    (point.title=='Membership' && auth.ismember !='true'? (<> 
                                        <Button onClick={e=> {navigate('/membership')}}> Learn More</Button>
                                    </>):(<></>))
                                }
                            </Card.Body>
                        </Card>
                    </Col> 
                )
            })}
            </Row>         
                
                {/* <Card className='mt-10'>
                    <Card.Body >
                    <h3> 
                        Welcome to CStaRS
                        <Linkedin size={20} className="mx-3" id="linkedin" onClick = { (e)=> { relocateTo (e) }}/> 
                        <Instagram size={20} id="instagram" onClick = { (e)=> { relocateTo (e) }}/>
                    </h3>
                        <p>
                            Are you a Canadian student, resident, or trainee interested in Aerospace Medicine?
                        </p>

                        <p>
                            The Canadian Society for Aerospace Medicine (CSAM) is developing 
                            CStaRS (Canadian Society for Aerospace Medicine Student & Resident 
                            Subgroup) which is a student-led group aiming to make aerospace 
                            medicine opportunities and initiatives more accessible to students 
                            and residents across Canada. It will be a great way to connect 
                            and share passions for aerospace medicine! Our main goal 
                            is to centralize research, opportunities, and advancements in 
                            Canadian aerospace medicine to develop and expand the landscape 
                            of this field for students across the country. 
                            Being involved will allow you to connect with likeminded students 
                            and mentors and have access to incredible opportunities to get 
                            involved in the field as well as outreach these opportunities 
                            to other prospective students.
                        </p>   
                        <br />

                        <h4> Three simple steps to join: </h4>
                        <ol>
                            <li> First create an account </li> 
                            <li> Sign up to become a CSAM-CStaRS offical member at a discounted price of (15$/year). </li>
                            <li> Recieve access to the CSAM-SCMA Slack, and join the CStaRS Channel on slack to get plugged in! </li>
                        </ol>      
                        <p></p>
                    </Card.Body>
                </Card> */}
        </div>
    )
}
export default function HomeCSTARS() {
    const { auth, setAuth } = useContext(AuthContext)
    const navigate = useNavigate()

    const [loading, isLoading] = useState(true)
    const [isMounted, didMount] = useState(false)
    const [seed, setSeed] = useState(1)
    const [errMessage, setErrMessage] = useState('')
    const [view, setViewTo] = useState('cstar-main')

    useEffect(()=> {
        didMount(true)
    }, [])

    useEffect(()=> {
        isLoading(false)
    }, [isMounted])

    const scrollTo = (e, id) => {
        e.preventDefault()
        const element = document.getElementById(id);
        element.scrollIntoView();
    }
    if (!loading) return (
        <Container className='mt-100'>

            <Row>
                <Col></Col>
                <Col xs={10}>
                    <Navbar bg="light" data-bs-theme="light">
                        <Container>
                        <Navbar.Brand onClick={e=> {setViewTo('cstar-main')} }>CStaRS</Navbar.Brand>
                        <Nav className="me-auto">
                            <Nav.Link onClick={e=> {setViewTo('cstar-main')}}>Home</Nav.Link>
                            <Nav.Link onClick={e=> {navigate('/opportunities')}}>Opportunites</Nav.Link>
                           {/* <Nav.Link onClick={e=> {setViewTo('opportunities')}}>Opportunities</Nav.Link>  */}
                           {/* <Nav.Link onClick={e=> {setViewTo('opportunities')}}> CStaRS Spotlight</Nav.Link>  */}
                        </Nav>
                        </Container>
                    </Navbar>
                </Col>
                <Col></Col>
            </Row>

            <Row className={ view == 'cstar-main' ? ('show'):('hide') } id='cstar-main'>
                <Col></Col>
                <Col xs={10}>
                    <MainBodyCSTARS />

                    <Gallery /> 



                </Col>
                <Col></Col>
            </Row>

        </Container>

      )

    }